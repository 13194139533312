<template>
  <div :class="!$vuetify.breakpoint.mobile?'login':''">
    <v-container :fill-height="!$vuetify.breakpoint.mobile">
        <v-row justify="center" align="center" style="height: 90%" class="text-center">
          <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3" class="d-flex justify-center align-center">
            <div
              v-if="screen == 'login'"
              style="
                background: rgba(141, 141, 141, 0.1) !important;
                background-color: rgba(141, 141, 141, 0.1) !important;
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(6.9px);
                -webkit-backdrop-filter: blur(6.9px);
                border: 1px solid rgba(255, 255, 255, 0.27);
                z-index: 10;
                max-width: 95%;
              "
            >
              <v-img
                max-width="60%"
                max-height="180px"
                contain
                :src="tenantBrand.logoUrl.landingLight"
                class="mt-4 mb-1 mx-auto"
              />
              <v-col
                cols="12"
                class="text-center my-0 py-0"
                v-if="tenantBrand.label == 'LØGLIVE'"
              >
                <div class="logo-text">
                  <span>-</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">Ø</span>
                  <span class="loading-text-words">G</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">I</span>
                  <span class="loading-text-words">V</span>
                  <span class="loading-text-words">E</span>
                  <span class="loading-text-words">-</span>
                </div>
              </v-col>
              <v-card-subtitle
                v-if="!$vuetify.breakpoint.mobile"
                class="my-0 py-0 mb-1"
              >
                <span style="color: white; font-size: 14px"
                  ><i>“Don't waste your time looking back.</i> <br /><i
                    >You're not going that way.”</i
                  >
                  <br />
                  <b>Ragnar Lodbrok</b></span
                >
              </v-card-subtitle>
              <v-card-text>
                <v-row class="my-1 py-1">
                <v-col cols="12" sm="12" md="6" class="py-0 my-0">
                  <v-text-field
                    ref="firstname"
                    dense
                    label="Name"
                    outlined
                    v-model="model.firstname"
                  >
                    <template v-slot:append>
                      <v-icon v-if="model.firstname" slot="suffix" color="green"
                        >check</v-icon
                      >
                      <v-icon v-else slot="suffix" color="orange"
                        >warning</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6" class="py-0 my-0">
                  <v-text-field
                    ref="surname"
                    dense
                    label="Surname"
                    outlined
                    v-model="model.surname"
                  >
                    <template v-slot:append>
                      <v-icon v-if="model.surname" slot="suffix" color="green"
                        >check</v-icon
                      >
                      <v-icon v-else slot="suffix" color="orange"
                        >warning</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" class="py-0 my-0">
                  <v-text-field
                    :disabled="$route.query.user != null"
                    ref="email"
                    dense
                    label="Email Address"
                    type="email"
                    outlined
                    prepend-inner-icon="email"
                    v-model="model.emailAddress"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-if="validateEmail(model.emailAddress)"
                        slot="suffix"
                        color="green"
                        >check</v-icon
                      >
                      <v-icon v-else slot="suffix" color="orange"
                        >warning</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" class="py-0 my-0">
                  <v-text-field
                    :rules="[rules.counter]"
                    ref="password"
                    dense
                    label="Password"
                    outlined
                    prepend-inner-icon="lock"
                    v-model="model.password"
                    :type="passwordType"
                    :append-icon="
                      passwordType == 'text' ? 'visibility' : 'visibility_off'
                    "
                    @click:append="changePasswordVisibility"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" class="py-0 my-0">
                  <v-text-field
                    :rules="[rules.matchPassword]"
                    ref="confirmPassword"
                    dense
                    label="Confirm Password"
                    :disabled="!model.password"
                    outlined
                    prepend-inner-icon="lock"
                    v-model="model.confirmPassword"
                    :type="passwordType"
                    :append-icon="
                      passwordType == 'text' ? 'visibility' : 'visibility_off'
                    "
                    @click:append="changePasswordVisibility"
                  ></v-text-field>
                </v-col>
              </v-row>
  
              <div v-if="!loading" class="my-0 py-0">
                  <v-row justify="center">
                    <v-col cols="10" sm="10" md="8" class="text-center pb-1 mb-1 mt-0 pt-0">
                      <v-btn color="primary" :disabled="!model.firstname ||
                        !model.surname ||
                        !model.emailAddress ||
                        !model.password ||
                        !model.confirmPassword ||
                        !checkPassword()"
                        class="white--text login-button" @click="registerUser" :loading="loading">{{ !loading ? "Register" : ""
                        }}</v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center py-1 my-1 px-3" :key="googleKey">
                      <v-row justify="center" class="my-2">
                        <div style="right: 0" id="buttonDiv"></div>
                      </v-row>
                    </v-col>
                  </v-row>

                  <br />
                  <a
                  class="forgot-password"
                  @click="$router.push({ path: '/login' })"
                  style="cursor: pointer"
                  >Already got an account?</a
                >
                </div>
                <div v-else style="height: 100px; padding-top: 0; margin-top: 0">
                </div>
              </v-card-text>
            </div>
            </v-col>
            </v-row>
            </v-container>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "login",
  data() {
    return {
      screen: "login",
      model: {
        emailAddress: "",
        password: "",
      },
      passwordType: "password",
      googleKey: 1000,
      isInit: false,
      isSignIn: false,
      loading: false,
      rules: {
        counter: (value) => value && value.length >= 8 || "Min 8 characters",
        matchPassword: (value) =>
          value == this.model.password || "Passwords do not match",
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
          {
            min: 8,
            message: "Password length should be at least 8 characters",
            trigger: "blur",
          },
        ],
        // confirmPassword: [{validator: checkPassword(this.model), message: 'Passwords need to match', trigger:'blur' }]
      },
      type: "signup",
    };
  },
  watch: {
    "$route.query.user": {
      immediate: true,
      async handler(val) {
        if (val) {
          let user = await this.$API.getUserByHash(this.$route.query.user);
          if (user) {
            this.model = {
              id: user.id,
              firstname: user.firstname,
              surname: user.surname,
              emailAddress: user.emailAddress,
            };
            this.$refs["password"].$refs.input.focus();
            this.type = "invite";
          } else {
            this.$refs["firstname"].$refs.input.focus();
            this.type = "signup";
          }
        } else {
          this.$refs["firstname"].$refs.input.focus();
          this.type = "signup";
        }
      },
    },
  },
  async created() {
    // window.onload = function () {
      setTimeout(()=>{
        this.google = window.google
    this.google.accounts.id.initialize({
      client_id: "999037980508-s1q1aft4n01cgltnv71lbne87eu1lnj9.apps.googleusercontent.com",
      itp_support: true,
      callback: this.handleCredentialResponse
    });
      }, 250)
    let now = new Date()
    now = now.getTime()
      setTimeout(() => {
        this.google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large", shape: "pill", width: "250px", logo_alignment: "left", text: "Sign up as" }  // customization attributes
        );
        this.google.accounts.id.prompt();
      }, 2000)
      await this.$store.dispatch("setAuthState", "signedout");
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setTokenExpiry", null);
      // this.$store.dispatch("setAuthState", null);
      this.$store.dispatch("setOrganisations", null);
      this.$store.dispatch("setCurrentOrg", null);
      this.$store.dispatch("setOrgToken", null);    // }
    // this.loading = false
  },
  computed: {
    ...mapGetters(['tenantBrand'])
  },
  methods: {
    handleCredentialResponse(response) {
      this.authGoogleUserData(response.credential);
    },
    changePasswordVisibility() {
      if (this.passwordType == "text") {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },
    checkPassword() {
      return this.model.password === this.model.confirmPassword;
    },
    async handleClickSignIn() {
      this.google.accounts.id.initialize({
        client_id: "999037980508-s1q1aft4n01cgltnv71lbne87eu1lnj9.apps.googleusercontent.com",
        callback: this.handleCredentialResponse
      });
      this.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      this.google.accounts.id.prompt();
    },
    async login() {
      this.loading = true;
      let access = await this.$API.systemLogin(this.model);
      if (access.code == 200) {
        this.$message.success(access.message);
        await this.$store.dispatch(
          "setToken",
          access.token.type + access.token.accessToken
        );
        await this.$store.dispatch("setTokenExpiry", access.token.expiry);
        await this.$store.dispatch("setUser", access.user);
        await this.$store.dispatch("setAuthState", "signedin");
        // this.$router.push({
        //     path: '/landing'
        // })
        setTimeout(() => {
          this.$router.push({
            path: "/landing",
          });
        }, 500);
      } else {
        this.$message.error(access.message);
      }
      this.loading = false;
    },
    async registerUser() {
      try {
        this.loading = true;
        await this.$API.registerUser(this.model, {
          params: {
            hash: this.$route.query.user,
          }
        });
        this.login();
        // this.loading = false
      } catch (e) {
        this.$message.error("An error occurred.");
      }
    },

    async authGoogleUserData(access_token) {
      this.loading = true
      let access = await this.$API.authGoogleRegisterUser({
        access_token: access_token,
      },{
        params: {
          hash: this.$route.query.user
        }
      });
      if (access.code == 200) {
        this.$message.success(access.message);
        await this.$store.dispatch(
          "setToken",
          access.token.type + access.token.accessToken
        );
        await this.$store.dispatch("setTokenExpiry", access.token.expiry);
        await this.$store.dispatch("setUser", access.user);
        await this.$store.dispatch("setAuthState", "signedin");
        this.$router.push({
          path: "/landing",
        });
        this.loading = false;
      } else {
        this.$message.error(access.message);
        this.loading = false;
      }
    },
    validateEmail(email) {
      let result = false;
      if (email) {
        result = email.match(
          //eslint-disable-next-line
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      }
      return result;
    },
  },
};
</script>

<style scoped>
.login {
  flex: 1;
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: slategray; */
}

.login-button {
  width: 100%;
  margin-top: 10px;
}

.login-form {
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 10px;
}

.forgot-password {
  font-size: 14px;
  margin-top: 10px;
}
</style>
<style lang="scss">
$teal: green;

.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }

  &:disabled {
    background: grey;
    color: white;
  }
}

.login .el-input__inner:hover {
  border-color: $teal;
}

.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;

  .el-input__icon {
    width: 30px;
  }
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}

.login .el-card {
  border-radius: 25px;
  min-width: 70%;
  max-width: 350px;
  display: flex;
  justify-content: center;
}
</style>
